import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import  React from 'react';
import { lazy, Suspense } from 'react';
import logo from './resources/photo/LogoGD.png'

const LazyFirst = lazy(() => import('./resources/first'));
const LazyProduct = lazy(() => import('./resources/product'));
const LazyFirstEn = lazy(() => import('./resources/english/first'));
const LazyProductEn = lazy(() => import('./resources/english/product'));


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Suspense fallback={<div className='load' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', textAlign:'center',}}><img src={logo} alt='Glass Dream' /> <h1 >კეთილი იყოს თქვენი მობრძანება გლასს დრიმში<br></br>Welcome to Glass Dream <br></br> Loading...</h1></div>}><LazyFirst /></Suspense>} />
        <Route path="/product" element={<Suspense fallback={<div className='load' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', textAlign:'center'}}><img src={logo} alt='Glass Dream' /> <h1 >კეთილი იყოს თქვენი მობრძანება გლასს დრიმში<br></br>Welcome to Glass Dream <br></br> Loading...</h1></div>}><LazyProduct /></Suspense>} />
        <Route path='/en' element={<Suspense fallback={<div className='load' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', textAlign:'center'}}><img src={logo} alt='Glass Dream' /> <h1 >კეთილი იყოს თქვენი მობრძანება გლასს დრიმში<br></br>Welcome to Glass Dream <br></br> Loading...</h1></div>}><LazyFirstEn /></Suspense>} />
        <Route path='/en/product' element={<Suspense fallback={<div className='load' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', textAlign:'center'}}><img src={logo} alt='Glass Dream' /> <h1 >კეთილი იყოს თქვენი მობრძანება გლასს დრიმში<br></br>Welcome to Glass Dream <br></br> Loading...</h1></div>}><LazyProductEn /></Suspense>} />
      </Routes>
    </Router>
  );
}

export default App;
